<template>
	
	<router-link :to="( btn_class && btn_class.includes( 'opacity-50' ) ? '' : route )" class="d-inline-flex align-items-center" :class="( !btn_class ? 'btn btn-outline-primary' : btn_class )">
		
		<span v-if="!icon_after" class="material-icons-outlined me-2">{{ ( icon ? icon : 'east' ) }}</span>

		{{ label }}

		<span v-if="icon_after" class="material-icons-outlined ms-2">{{ ( icon ? icon : 'east' ) }}</span>

	</router-link>

</template>

<script>
export default {
	name: 'components.btn_router_link',
	props: {
		route: Object,
		label: String,
		icon: String,
		icon_after: Boolean,
		btn_class: String
	}
}
</script>

<style>

</style>
