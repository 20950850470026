<template>
	
	<ul v-if="statuses" class="list-group">

		<li v-for="( stat, stat_key ) in statuses" :key="'status-' + stat_key" class="list-group-item d-flex align-items-center">

			<div style="width: 24px; height: 24px; text-align: center;" class="me-3 d-flex align-items-center justify-content-center">

				<span v-if="stat.status == 'complete'" class="material-icons-outlined text-success">check_circle</span>

				<span v-if="stat.status == 'waiting'" class="material-icons-outlined" style="color: #ccc;">{{ stat.icon }}</span>

				<span v-if="stat.status == 'processing'">
					<span class="spinner-border spinner-border-sm" role="status">
						<span class="visually-hidden">Loading...</span>
					</span>
				</span>

			</div>

			<span :class="( stat.status == 'processing' ? 'text-primary' : ( stat.status == 'complete' ? 'text-success' : '' ) )" class="form-text m-0">{{ stat.message }}</span>

		</li>
	</ul>

</template>

<script>
export default {
	name: 'components.statuses',
	props: {
		statuses: Array,
	}
}
</script>

<style>

</style>
