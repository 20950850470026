<template>

	<layout :include_header="true">

		<template v-slot:header-center>

			<main_nav />

			<workspace_nav v-if="auth_user && ( workspace && workspace.is_created )" />

		</template>

		<template v-slot:header-end>

			<support_link />

		</template>

		<template v-slot:main-body>
			
			<div class="container h-100 py-4">
				
				<div class="row h-100">

					<div class="col-12">

						<loader v-if="workspace_loading || sites_loading" />

						<div v-else-if="workspace && !workspace.is_created && !workspace.error_message">

							<div class="row h-100 justify-content-center">

								<div class="col-12 col-md-10 col-lg-6 col-xxl-5">

									<div class="card">

										<div class="card-body">

											<h1>Workspace is processing.</h1>

											<p v-if="error.message" class="alert alert-danger text-start">
												{{ error.message }}
											</p>

											<p class="alert alert-success text-start d-flex align-items-center">
												<span class="material-icons-outlined me-2">check_circle</span>
												We are currently creating your {{ workspace.name }} workspace.
											</p>

											<p>This will take around 10 minutes. Don't feel like you have to wait around, we'll send you an email when it's ready for you.</p>

											<statuses :statuses="workspace.create_status" />

										</div>

									</div>

								</div>

							</div>

						</div>

						<div v-else-if="!workspace">

							<div class="row h-100 justify-content-center">

								<div class="col-12 col-md-10 col-lg-6 col-xxl-5">

									<div class="card">

										<div class="card-body text-center">

											<h1>There was an issue.</h1>

											<p v-if="error.message" class="alert alert-danger text-start">
												{{ error.message }}
											</p>

											<btn_router_link 
												label="View all workspaces" 
												:route="{ name: 'workspaces' }" 
												icon="workspaces" 
											/>

										</div>

									</div>

								</div>

							</div>

						</div>

						<div v-else-if="!sites.length">

							<div class="row h-100 justify-content-center">

								<div class="col-12 col-md-10 col-lg-6 col-xxl-5">

									<div class="card">

										<div class="card-body text-center">

											<span class="material-icons-outlined card-icon mx-auto mb-4">web</span>

											<h1>Add a site.</h1>

											<div v-if="auth_user.type != 'viewer'">

												<p>Now that your workspace is setup, it's time to create your first site. Click the button below to get started.</p>

												<btn_router_link
													label="Add site" 
													:route="{ name: 'site.add' }" 
													icon="add_circle_outline"
													btn_class="btn btn-lg btn-outline-secondary" 
												/>

											</div>

											<p v-else class="mb-0">Now that your workspace is setup, it's time to create your first site. Contact your team admin to setup your first site.</p>

										</div>

									</div>

								</div>

							</div>

						</div>

						<div v-else>

							<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">

								<div v-for="site in sites" class="col mb-4" :key="'site-' + site.id">

									<div class="card h-100 position-relative">

										<div class="card-body position-relative">

											<p class="h5 m-0">{{ site.name }}</p>

											<p class="form-text mb-0 mt-2" v-if="site.slug">{{ workspace.domain }}/{{ site.slug }}</p>

											<span v-if="site.is_built" class="badge bg-success position-absolute top-0 end-0 mt-2 me-2">Active</span>

											<span v-else-if="!site.error_message" class="badge bg-info position-absolute top-0 end-0 mt-2 me-2">Processing</span>

											<span v-else class="badge bg-danger position-absolute top-0 end-0 mt-2 me-2">Failed</span>

										</div>

										<router-link :to="{ name: 'site.show', params: { site_id: site.id } }" class="stretched-link"></router-link>

									</div>

								</div>

							</div>

						</div>

					</div>

				</div>

			</div>

		</template>

	</layout>

</template>

<script>
import { mapGetters } from 'vuex';
import layout from '@/components/layout'
import loader from '@/components/loader'
import main_nav from '@/components/main_nav'
import statuses from '@/components/statuses'
import support_link from '@/components/support_link'
import btn_router_link from '@/components/btn_router_link'
import workspace_nav from '@/components/workspace_nav'
import workspace_service from '@/services/workspace_service'
import site_service from '@/services/site_service'

export default {
	name: 'workspaces',

	components: {
		layout,
		loader,
		main_nav,
		support_link,
		workspace_nav,
		btn_router_link,
		statuses
	},

	data() {
		return {
			workspace_loading: false,
			sites_loading: true,
			workspace: null,
			sites: [],
			error: {}
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

	},

	mounted()
	{
		this.get_workspace()

		this.get_sites()
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async get_workspace()
		{
			this.reset_error()

			workspace_service.show( this.$route.params.workspace_id ).then(( response ) => {
				this.workspace = response.data

				var timeout = 120000

				if ( this.workspace && this.workspace.create_status && this.workspace.create_status[0].status == 'complete' ) {
					timeout = 30000
				}

				if ( !this.workspace.is_created && !this.workspace.error_message ) {
					setTimeout(() => {
						this.get_workspace()
					}, timeout )
				}
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.workspace_loading = false
			})
		},

		async get_sites()
		{
			this.sites_loading = true 

			this.reset_error()

			site_service.index( this.$route.params.workspace_id ).then(( response ) => {
				this.sites = response.data
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.sites_loading = false
			})
		},
	}
}
</script>

<style>

</style>
