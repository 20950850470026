<template>
	
	<div class="">

		<div class="d-flex pe-2 w-100 align-items-center justify-content-between border-bottom position-relative">
			
			<div class="d-flex align-items-center justify-content-between">

				<div class="d-flex align-items-center">
					<div class="p-3 px-4 rounded-0 d-flex align-items-center justify-content-center fw-bold border-end" style="width: 270px;">
						{{ limitString( label, 20 ) }}
					</div>
					<router-link :to="{ name: 'workspaces' }" class="btn p-3 px-4 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2" :class="$route.name == 'workspaces' ? 'border-primary' : 'border-light'">
						<span class="material-icons-outlined me-2 d-none d-xxl-block">workspaces</span>
						Workspaces
					</router-link>
					<router-link :to="{ name: 'workspaces.add' }" class="btn p-3 px-4 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2 v-step-sitemap" :class="$route.name == 'workspaces.add' ? 'border-primary' : 'border-light'">
						<span class="material-icons-outlined me-2 d-none d-xxl-block">add_circle</span>
						Add workspace
					</router-link>
					
				</div>
			</div>

		</div>

	</div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'components.workspace_nav',

	props: {
		label: String
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

	},

	components: {
		
	},

	methods: {
		limitString(str, maxLength) 
		{
			if (str.length > maxLength) {
				return str.slice(0, maxLength - 3) + '...';
			}
			return str;
		}
	}
}
</script>

<style>

</style>
